<template>
  <div class="mod-wrap">
    <template v-for="mod in homeConfigData" :key="mod.detailId">
      <!-- 轮播图 -->
      <section v-if="mod.moduleType === 2">
        <Banner
          :module="mod"
          :dataSource="mod.dataList.BannerList"
          @jump="toBannerURL"
        />
      </section>
      <!-- 待办 -->
      <section v-else-if="mod.moduleType === 9 && todoData!=null && todoData.length>0">
        <Todo
          :module="mod"
          :langNamesEnum="langNamesEnum"
          :languageActive="languageActive"
          :dataSource="todoData"
          :todoDataTotal="todoDataTotal"
        />
      </section>
      <!-- 课程专题 -->
      <section v-else-if="mod.moduleType === 3 && mod.dataType === 7">
        <Subject
          :module="mod"
          :langNamesEnum="langNamesEnum"
          :languageActive="languageActive"
          :dataSource="mod.dataList.SubjectList"
        />
      </section>
      <!-- 排行榜 -->
      <section v-else-if="mod.moduleType === 6">
        <Ranking
          :module="mod"
          :langNamesEnum="langNamesEnum"
          :languageActive="languageActive"
        />
      </section>
      <!-- 金牌讲师 -->
      <section v-else-if="mod.moduleType === 3 && mod.dataType === 2">
        <Lecturer
          :module="mod"
          :langNamesEnum="langNamesEnum"
          :languageActive="languageActive"
          :dataSource="mod.dataList.LecturerList"
        />
      </section>
      <!-- 新闻动态 -->
      <section v-else-if="mod.moduleType === 3 && mod.dataType === 4">
        <News
          :module="mod"
          :langNamesEnum="langNamesEnum"
          :languageActive="languageActive"
          :dataSource="mod.dataList.NewsList"
        />
      </section>
      <!-- 热门知识 -->
      <section v-else-if="mod.moduleType === 3 && mod.dataType === 8">
        <Knowledge
          :module="mod"
          :langNamesEnum="langNamesEnum"
          :languageActive="languageActive"
          :dataSource="mod.dataList.KnowledgeList"
        />
      </section>
      <!-- 推荐课程 -->
      <section v-else-if="mod.moduleType === 3 && mod.dataType === 1">
        <Course
          :module="mod"
          :langNamesEnum="langNamesEnum"
          :languageActive="languageActive"
          :dataSource="mod.dataList.CourseList"
        />
      </section>
      <!-- 推广图 -->
      <section v-else-if="mod.moduleType === 4 && mod.dataType === 5">
        <Ad
          :module="mod"
          :dataSource="mod.dataList.BannerList[0]"
          @jump="toBannerURL"
        />
      </section>
    </template>
    <section v-if="homeConfigData.length == 2">
      <div class="no-data-card">
        <a-empty
          :image="require('@/assets/image/no_data_1.png')"
          :image-style="{
            height: '128px',
          }"
        >
          <template #description>
            <div class="tips">{{ $t("Home.NoData") }}</div>
          </template>
        </a-empty>
      </div>
    </section>
  </div>
  <div class="init-loading" v-if="initLoading">
    <div>
      <LoadingOutlined />
      <p>
        {{ $t("Home.init")
        }}<!-- 首页数据初始化中，请稍等。 -->
      </p>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Banner from "@/views/home/Banner.vue";
import Todo from "@/views/home/Todo.vue";
import Course from "@/views/home/Course.vue";
import Subject from "@/views/home/Subject.vue";
import News from "@/views/home/News.vue";
import Knowledge from "@/views/home/Knowledge.vue";
import Lecturer from "@/views/home/Lecturer.vue";
import Ranking from "@/views/home/Ranking.vue";
import Ad from "@/views/home/Ad.vue";
import { getBanner } from "@/api/other";
import { companyInfo, homeConfig } from "@/api/user";
import { newTaskList } from "@/api/project";
import { formatTime, getMenuIsExist } from "@/utils/tools";
export default {
  components: {
    Banner,
    Todo,
    Course,
    Subject,
    News,
    Knowledge,
    Lecturer,
    Ranking,
    Ad,
  },
  setup() {
    const store = useStore();

    const langNamesEnum = {
      "zh-CN": "zh_CN",
      "zh-TW": "zh_TW",
      "en-US": "en_US",
    };
    const languageActive = computed(() => store.getters.lang);

    const homeConfigData = ref([]);

    const initTimer = ref(null);
    const initLoading = ref(false);
    const isInitComplete = () => {
      homeConfig().then((res) => {
        if (res.ret == 0) {
          let data = res.data.data || [];
          if (!data.length) {
            initLoading.value = true;
          } else {
            initLoading.value = false;
            clearInterval(initTimer.value);
            data.forEach((item) => {
              if (item.menuJson) {
                item.menuJson = JSON.parse(item.menuJson);
                item.menuJsonObj = {};
                item.menuJson.forEach((item2) => {
                  if (item2.key) item.menuJsonObj[item2.key] = item2.value;
                });
              }
              if (item.moduleType == 6) {
                if (item.rankingSettingJson) {
                  let o = JSON.parse(item.rankingSettingJson);
                  item.rankType = o.rankType || 1;
                } else {
                  item.rankType = 1;
                }
              }
            });
            homeConfigData.value = data;
          }
        }
      });
    };
    initTimer.value = setInterval(() => {
      isInitComplete();
    }, 4000);
    isInitComplete();

    // 轮播图
    getBanner().then(async (res) => {
      if (res.ret === 0 && res.data) {
        if (!res.data && !res.data.length) return false;
        let otherBanner = [];
        res.data = res.data.filter((item) => {
          if (item.bannerType != 1 && item.bannerType != 0) {
            otherBanner.push({
              type: item.bannerType,
              coverPath: item.coverPath,
            });
          }
          return item.bannerType == 1 || item.bannerType == 0;
        });
        store.dispatch("setOtherBanner", otherBanner);
      }
    });
    let urlStr = "";
    const toBannerURL = (item) => {
      if (item.jumpType == 1) {
        if (item.jumpUrl != "") {
          urlStr =
            item.jumpUrl.indexOf("http") != -1
              ? item.jumpUrl
              : "http://" + item.jumpUrl;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(
              urlStr.includes("?")
                ? `${urlStr}&ddtab=true`
                : `${urlStr}?ddtab=true`
            );
          } else {
            window.location.href = urlStr;
          }
        }
      } else {
        if (item.resourceId != 0) {
          switch (item.resourceType) {
            case 1: //知识
              urlStr = "/doc/detail?id=" + item.resourceId;
              if (store.getters.companyInfo.useNewWindow == 1) {
                window.open(urlStr + "&ddtab=true");
              } else {
                window.location.href = urlStr;
              }
              break;
            case 2: //课程
              urlStr = "/course/detail?id=" + item.resourceId;
              if (store.getters.companyInfo.useNewWindow == 1) {
                window.open(urlStr + "&ddtab=true");
              } else {
                window.location.href = urlStr;
              }
              break;
            case 34: //项目
              urlStr = "/project/detail?id=" + item.resourceId;
              if (store.getters.companyInfo.useNewWindow == 1) {
                window.open(urlStr + "&ddtab=true");
              } else {
                window.location.href = urlStr;
              }
              break;
          }
        }
      }
    };

    const taskTypeEnum = {
      project: 1, // 培训项目
      practice: 2, // 练习
      microClassCompetition: 4, // 微课大赛
      subject: 5, // 专题培训
      map: 6, // 学习地图
      trainingClass: 7, // 培训班
      package: 8, // 培训包
      newStaff: 9, // 新员工培训
      exam: 10, // 考试任务
      supervision: 11, // 任务督导
      activity: 12, // 活动
      solicit: 13, // 征集需求
      implement: 14, // 培训计划实施
      contest: 15, // 答题竞赛
      train: 16, // 面授
      studyTrain: 17, // 学员面授
      lecturer: 18, // 讲师面授
      attendanceTrain: 19, // 考勤面授
      live: 20, // 直播
      examreview: 21, // 考试批阅
      trainindex: 16, // 独立面授
    };
    let menu = [];
    let menuOfNum = [];
    // 待办任务
    const todoData = ref([]);
    const todoDataTotal = ref(0);
    const getTodo = () => {
      const params = {
        name: "",
        page: 1,
        pageSize: 3,
      };
      newTaskList(params).then((res) => {
        if (res.ret === 0 && res.data) {
          todoData.value = res.data.list;
          todoDataTotal.value = res.data.totals;
        }else{
          todoData.value =[];
        }
      });
    };

    // 我的任务
    const getTask = async () => {
      const res = await companyInfo();
      menu = res.ret === 0 ? res.data.menu : [];
      for (let i = 0; i < menu.length; i++) {
        if (taskTypeEnum[menu[i]]) menuOfNum.push(taskTypeEnum[menu[i]]);
      }
      getTodo();
    };
    getTask();

    return {
      langNamesEnum,
      languageActive,
      homeConfigData,
      todoData,
      todoDataTotal,
      toBannerURL,
      formatTime,
      getMenuIsExist,
      initLoading,
    };
  },
};
</script>

<style lang="less" scoped>
.init-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  color: var(--theme);
}
.mod-wrap {
  & > section {
    padding: 30px 0;
    background-color: #fff;
    &:first-child {
      padding: 0;
    }
    &:nth-child(4n -1) {
      background-color: #fafafa;
    }
  }
  .no-data-card {
    padding: 150px 0 130px;
    .tips {
      color: #999;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 10px;
      user-select: none;
    }
  }
}
</style>
