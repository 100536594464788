<template>
  <div class="course">
    <div class="content">
      <ModTit
        :title="
          module.menuJsonObj
            ? module.menuJsonObj[langNamesEnum[languageActive]]
            : module.title
        "
        :moreText="$t('Home.SeeMore')"
        :more="{ path: '/course' }"
      />
      <div
        class="card"
        :class="{
          four: module.dataCountRow === 4,
          two: module.dataCountRow === 2,
        }"
      >
        <template v-if="dataSource.length">
          <a
            class="item"
            v-for="(item, index) in dataSource"
            :key="index"
            :href="`/course/detail?id=${item.courseId}${
              newWindow == 1 ? '&ddtab=true' : ''
            }`"
            :target="newWindow == 1 ? '_blank' : '_self'"
          >
            <div class="label">
              <span v-if="item.labelId == 3"
                >{{ $t("Home.Newly")
                }}<!-- 新上架 --></span
              >
              <div class="img" v-else>
                <img
                  v-if="item.labelId == 1"
                  :src="require('@/assets/image/subject_new.png')"
                  alt="new"
                />
                <img
                  v-if="item.labelId == 2"
                  :src="require('@/assets/image/subject_hot.png')"
                  alt="hot"
                />
              </div>
            </div>
            <div class="cover">
              <img :src="item.coverImg" alt="cover" />
            </div>
            <div class="info">
              <div class="title">{{ item.courseName }}</div>
              <div class="bottom">
                <span v-if="item.lecturers?.length">
                  <OpenData type="userName" :openid="item.lecturers[0].name" />
                  <template v-if="item.lecturers.length > 1">...</template>
                </span>
                <span v-else
                  >{{ $t("Home.NoLecturer")
                  }}<!-- 暂无讲师 --></span
                >
                <span>{{ $t("LB_NumPersonLearning", [item.learnCount]) }}</span>
              </div>
            </div>
          </a>
        </template>
        <div class="empty-box" v-else>
          <a-empty
            :image="require('@/assets/image/empty.png')"
            :image-style="{
              height: '144px',
            }"
          >
            <template #description>
              <div class="empty-tips">{{ $t("Pub_Lab_NoData") }}</div>
            </template>
          </a-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import OpenData from "@/components/OpenData.vue";
import ModTit from "@/components/layout/ModTit.vue";
export default {
  name: "course",
  components: {
    OpenData,
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const store = useStore();
    return {
      newWindow: store.getters.companyInfo.useNewWindow,
    };
  },
};
</script>

<style lang="less" scoped>
.course {
  .content {
    .mixinWrap();
    margin-bottom: -20px;
    .card {
      .mixinFlex();
      flex-wrap: wrap;
      .item {
        border-radius: 8px;
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
        cursor: pointer;
        position: relative;
        transition: all 0.3s ease;
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 6px 20px rgba(148, 148, 148, 0.15);
          .info {
            .title {
              color: @color-theme;
            }
          }
        }
        .label {
          position: absolute;
          left: 8px;
          top: 8px;
          z-index: 1;
          .img {
            .mixinImgWrap(28px; 28px);
          }
          span {
            height: 28px;
            padding: 0 8px;
            border-radius: 28px;
            background-color: #fff;
            color: #44b44f;
            font-size: 14px;
            line-height: 28px;
          }
        }
        .cover {
          .mixinImgWrap(285px; 161px);
        }
        .info {
          .title {
            color: #333;
            font-size: 16px;
            font-weight: 500;
            .mixinEllipsis(52px, 2);
          }
          .bottom {
            color: #999;
            font-size: 14px;
            line-height: 20px;
            .mixinFlex(space-between; center);
          }
        }
      }
      &.two {
        .item {
          width: 590px;
          height: 161px;
          margin-right: 20px;
          .mixinFlex(space-between; center);
          &:nth-child(2n) {
            margin-right: 0;
          }
          .info {
            width: 305px;
            padding: 16px 20px;
            .bottom {
              margin-top: 57px;
            }
          }
        }
      }
      &.four {
        .item {
          width: 285px;
          height: 309px;
          margin-right: 20px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .info {
            padding: 20px 16px;
            .bottom {
              margin-top: 36px;
            }
          }
        }
      }
    }
  }
}
.empty-box {
  padding: 70px 0;
  width: 100%;
  .empty-tips {
    color: #999;
    font-size: 14px;
    margin-top: 15px;
  }
}
</style>
